<template>
  <div class="change-password">
    <Banner isBig />
    <div class="change-password-content page-layout-big">
      <LoadingCard v-if="isLoading" />
      <md-card class="meep-form md-card-small-noheight">
        <PageHeader
          :title="$t('change-password.title')"
          :has-back="true"
          @back="goBack"
        />

        <md-card-content>
          <div class="meep-form-column meep-form-single-column">
            <p
              class="meep-annoucement"
              v-html="$t('change-password.annoucement')"
            />
            <!-- Old Password -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("change-password.old-password") }}</label>
                <md-input
                  v-model="$v.form.oldPassword.$model"
                  name="oldPassword"
                  type="password"
                >
                </md-input>
                <template v-if="$v.form.oldPassword.$dirty">
                  <span
                    v-show="!$v.form.oldPassword.required"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- New Password -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('newPassword') }">
                <label>{{ $t("change-password.new-password") }}</label>
                <md-input
                  v-model="$v.form.newPassword.$model"
                  name="newPassword"
                  type="password"
                >
                </md-input>
                <template v-if="$v.form.newPassword.$dirty">
                  <span
                    v-show="!$v.form.newPassword.required"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.required") }}
                  </span>
                  <span
                    v-show="!$v.form.newPassword.password"
                    class="meep-error"
                  >
                    {{ $t("error.new-password") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Confirm New Password -->
            <div class="meep-input">
              <md-field
                :class="{ 'md-invalid': errors.has('confirmNewPassword') }"
              >
                <label>{{ $t("change-password.confirm-password") }}*</label>
                <md-input
                  v-model="$v.form.confirmNewPassword.$model"
                  name="confirmNewPassword"
                  type="password"
                >
                </md-input>
                <template v-if="$v.form.confirmNewPassword.$dirty">
                  <span
                    v-show="!$v.form.confirmNewPassword.required"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.required") }}
                  </span>
                  <span
                    v-show="
                      !$v.form.confirmNewPassword.sameAsPassword &&
                        $v.form.confirmNewPassword.required
                    "
                    class="meep-error"
                  >
                    {{ $t("change-password.verify") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div class="md-layout md-alignment-center-right">
              <md-button class="md-raised md-primary" @click="changePassword">
                {{ $t("users.view.confỉm-button") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
// import Model

import Banner from "@/components/Banner";
import HasBackMixin from "@/mixins/HasBackMixin";
import PageHeader from "@/components/PageHeader";
import { required, sameAs } from "vuelidate/lib/validators";
import usersModel from "../../model/users";
import { password } from "../../services/vuelidate";
import { mapGetters } from "vuex";

export default {
  name: "ChangePassword",

  components: {
    Banner,
    PageHeader,
  },

  mixins: [HasBackMixin],

  validations: {
    form: {
      oldPassword: {
        required,
      },
      newPassword: {
        required,
        password,
      },

      confirmNewPassword: { required, sameAsPassword: sameAs("newPassword") },
    },
  },

  computed: {
    ...mapGetters(["isCE"]),

    meepAnnounceMentClass() {
      return {
        "meep-annoucement": this.isCE,
      };
    },
  },

  data() {
    return {
      isLoading: false,
      form: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
    };
  },

  methods: {
    async changePassword() {
      const result = !this.$v.$invalid;
      if (result) {
        try {
          await usersModel.changePassword(this.form);

          this.$toasted.global.AppSucces({
            message: this.$t("change-password.success-message"),
          });

          this.$router.push("/dashboard/information");
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        }
      } else {
        this.$v.$touch();
        this.$toasted.global.AppInfo({
          message: this.$t("error.required"),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.change-password {
  .md-alignment-center-right {
    margin-top: 50px;
  }
}
</style>
